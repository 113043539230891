.game-main-menu-root,
.game-main-menu {
    z-index: 100;
    width: 100%;
    height: 100%;
}

.game-main-menu {

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .main-menu-buttons {

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        @media screen and (min-width: 600px) {
            flex-direction: row;
        }

        img {
            width: 240px;
        }

    }

    .tutorial-container,
    .credits-container {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 300;
        width: 100%;
        height: 100%;
    }

    .tutorial-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
        height: 100%;

        .tutorial-row {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        .tutorial-overlay {
            background: rgba(255, 255, 255, 0.2);
            width: 80%;
            padding: 20px;
            border-radius: 30px;
        }

        .tutorial-page {
            @media screen and (max-width: 600px) {
                flex-direction: column;
            }

            @media screen and (min-width: 600px) {
                flex-direction: row;
            }

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;

        }

        .tutorial-page-description {
            @media screen and (max-width: 600px) {
                width: 100%;
            }

            @media screen and (min-width: 600px) {
                width: 50%;
            }

            display: inline-block;
            margin-right: 20px;

            h1 {
                text-align: center;
                font-size: 0.8em;
            }

            p {
                font-size: 0.6em;
                text-align: justify;
            }

            .tutorial-mandala {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            img {
                width: 100px;
            }
        }

        .tutorial-page-gif {
            @media screen and (max-width: 600px) {
                width: 100%;
            }

            @media screen and (min-width: 600px) {
                width: 50%;
            }

            display: inline-block;

            .tutorial-gif {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            img {
                width: 100%;
                border-radius: 20px;
            }
        }

        .tutorial-page-button {
            width: 50px;
        }
    }

    .credits-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        width: 100%;
        height: 100%;
        font-size: 0.6em;

        .credits-overlay {
            @media screen and (max-width: 600px) {
                width: 90%;
            }
                
            @media screen and (min-width: 600px) {
                width: 50%;
            }
            background: rgba(255, 255, 255, 0.2);
            text-align: center;
            vertical-align: middle;
            border-radius: 30px;
        }

        li {
            list-style-type: none;

            .credits-line {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-left: 20%;
                padding-right: 20%;

                .credits-left {
                    text-align: left;
                }

                .credits-right {
                    text-align: right;
                }
            }
        }
    }

}